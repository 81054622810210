$(document).ready(() => {
// E-mail Ajax Send
	$('form').submit(function () { // Change
		let th = $(this)
		$.ajax({
			type: 'POST',
			url: './form-submit/mail.php', // Change
			data: th.serialize()
		}).done(() => {
			// eslint-disable-next-line no-alert
			alert('Письмо отправлено, спасибо за обращение, мы с Вами свяжимся.')
			setTimeout(() => {
				// Done Functions
				th.trigger('reset')
			}, 1000)
		})

		return false
	})
})
